<template>
  <div>
    <click-to-edit
      v-model="scale.fact"
      type="select"
      select-filterable
      :select-options="scale.scopes"
      select-option-id="id"
      select-option-name="target_short"
      select-option-empty-name-text="Нет описания"
      select-cast-value-to-int
      :disabled="disabled"
      placeholder=""
      @input="$emit('change', scale.fact)"
    ></click-to-edit>
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import ClickToEdit from "@/components/ClickToEdit";

export default {
  name: "quality-card-fact-view",
  mixins: [dynamicScaleComponent],
  components: {ClickToEdit},

  props: {
    disabled:{type: Boolean, default: false}
  },
  mounted() {
    // If scope was deleted
    if( this.scale.scopes && !this.scale.scopes.find(s => s.id === +this.scale.fact) ){
      this.scale.fact = null;
    }
  },
  data() {
    return {
      scale:{
        fact: null,
        scopes: [],
      },
    }
  },
  methods: {}
}
</script>